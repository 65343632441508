import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import HomeIcon from '@material-ui/icons/Home';
import ViewAgenda from '@material-ui/icons/ViewAgenda';
import Add from '@material-ui/icons/Add';

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
		textAlign: 'center',
		height: '32px',
		lineHeight: '32px',
		boxShadow: 'none',
		borderRadius: 0,
		backgroundColor: 'rgba(255,255,255,0.5)'
	},
	link: {
		textDecoration: 'none',
		"&:hover, &:focus": {
			color: 'yellow',
			textDecoration: 'none'
		},
	},
	linkWithIcon: {
		position: 'relative',
		padding: '0 24px',
		textDecoration: 'none',
		"&:hover, &:focus": {
			color: 'yellow',
			textDecoration: 'none'
		},
	},
	icon: {
		position: 'absolute',
		top: 0,
		left: 0
	},
});

function MenuComponent() {
	const classes = useStyles();

	return (
		<Paper className={classes.root}>
			<Link href="/" className={classes.linkWithIcon}>
				<HomeIcon className={classes.icon} /> Home
			</Link>
			<Link href="/monthly" className={classes.linkWithIcon}>
				<ViewAgenda className={classes.icon} /> Monthly
			</Link>
			<Link href="/add" className={classes.linkWithIcon}>
				<Add className={classes.icon} /> Add food
			</Link>
		</Paper>
	);
}

export { MenuComponent }