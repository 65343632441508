const frontend = 'https://food.epicsoft.nl';
const backend = 'https://backend.food.epicsoft.nl';

export const environment = {
	production: true,
	test: false,
	backend: {
		server: backend,
		api: `${backend}/api`
	},
	frontend: {
		server: frontend
	}
};
