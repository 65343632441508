import React, { Component } from 'react';
import { MonthlyComponent } from '_components'
import { FoodType } from '_types'

type Props = {
	foods: FoodType[];
};

class MonthlyPage extends Component<Props, any> {
  	render() {
		return (
			<React.Fragment>
				{this.props.foods.map((food) => (
				<MonthlyComponent key={food.id} food={food} />
				))}
			</React.Fragment>
		);
	}
}

export { MonthlyPage };
