import React from 'react';
import { environment } from '_environments'
import { apiStates, useApi } from '_hooks'
import { MonthlyPage } from '_scenes'
import { FoodType } from '_types'

export const useMonthly = () => {
	document.body.classList.add('monthlyPage');
	const { state, error, data } = useApi(environment.backend.api + '/food/monthly');

	switch (state) {
		case apiStates.ERROR:
			return <p>ERROR: {error || 'General error'}</p>;
		case apiStates.SUCCESS:
			const foods: FoodType[] = data;
			return (
				<MonthlyPage foods={foods} />
			);
		default:
			return <p>loading..</p>;
	}
};