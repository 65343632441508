import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ThumbUpIcon from '@material-ui/icons/ThumbUpAltOutlined';
import { environment } from '_environments';
import { FoodType } from '_types'
import '../styles/Food.css';

type Props = {
	food: FoodType;
};

type States = {
	food: FoodType;
};

export class MonthlyComponent extends Component<Props, States> {
	constructor(props: Props) {
    super(props);
    this.state = {food: props.food};
  }

	render() {
		return (
			<div key={this.state.food.id} className="food">
				<img src={environment.backend.api + '/food/image?fileName=' + this.state.food.name} alt={'' + this.state.food.name} loading="lazy" />
				<span className="date">{new Date(this.state.food.date).getFullYear()} - {new Date(this.state.food.date).getMonth() + 1}</span>
				<IconButton aria-label="vote">
					<span>{this.state.food.count}</span>
					<ThumbUpIcon fontSize="large" />
				</IconButton>
			</div>
		);
	}
};