import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import './styles/App.css';
import { useUpload, useFoods, useMonthly } from '_hooks'
import { BreadcrumbsComponent, MenuComponent } from '_components'

function App() {
	return (
		<BrowserRouter>
			<MenuComponent />
			<Container maxWidth="lg">
				<BreadcrumbsComponent />
				<Switch>
					<Route exact path="/" component={useFoods} />
					<Route exact path="/monthly" component={useMonthly} />
					<Route exact path="/add" component={useUpload} />
				</Switch>
			</Container>
		</BrowserRouter>
	);
}

export default App;
