import React, { ChangeEvent, useState } from 'react';
import Button from '@material-ui/core/Button';
import ImageIcon from '@material-ui/icons/Image';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Alert, {Color} from '@material-ui/lab/Alert';
import { environment } from '_environments';

export const useUpload = () => {
	document.body.classList.add('aboutPage');
	const [file, setFile] = useState<File>();
	const [fileUrl, setFileUrl] = useState<any>();
	const [message, setMessage] = useState<String>();
	const [sending, setSending] = useState<Boolean>(false);
	const [messageType, setMessageType] = useState<Color | undefined>();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setFileUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const onResult = (response: Response) => {
    response.json();
    setMessage("Succes!");
    setMessageType("success");
    setSending(false);
    setFile(undefined);
    setFileUrl(undefined);
  };

  const onError = (error: any) => {
    console.error(error)
    setMessage("Something went wrong!");
    setMessageType("error");
    setSending(false);
    setFile(undefined);
    setFileUrl(undefined);
  };

  const handleUploadClick = () => {
    if (!file) {
      return;
    }
    setMessage("Sending image...");
    setMessageType("info");
    setSending(true);

    const data = new FormData();
    data.append("file", file);
    fetch(environment.backend.api + '/food', {
      method: 'POST',
      body: data
    })
      .then((res) => onResult(res))
      .then((data) => console.log(data))
      .catch((err) => onError(err));
  };

  return (
    <React.Fragment><div>
      {message && (<Alert severity={messageType}>{message}</Alert>)}
      <br />
      {!sending && (<>
        <input id="foodUpload" type="file" onChange={handleFileChange} accept="image/*" />
        <label htmlFor="foodUpload">
          <Button variant="contained" color="primary" component="span" startIcon={<ImageIcon />}>
            Select
          </Button>
        </label>
      </>)}
      {file && (<div>{`${file.name} - ${file.type}`}</div>)}
      {file && !sending && (<Button variant="contained" color="primary" size="large" onClick={handleUploadClick} startIcon={<CloudUploadIcon />}>
        Upload
      </Button>)}
      {fileUrl && (<img src={fileUrl} className="uploadPreview" />)}
    </div>
    </React.Fragment>);
};