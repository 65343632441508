import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ThumbUpIcon from '@material-ui/icons/ThumbUpAltOutlined';
import { environment } from '_environments';
import { FoodType } from '_types'
import '../styles/Food.css';

type Props = {
	food: FoodType;
};

type States = {
	food: FoodType;
};

export class FoodComponent extends Component<Props, States> {
	constructor(props: Props) {
    super(props);
    this.state = {food: props.food};
  }

	update(food: FoodType): void {
		environment.test && console.log(food);
		
		this.setState({
      food: food
    });
	}

	vote(): void {
		const data = new FormData();
    data.append("Id", this.props.food.id);
    fetch(environment.backend.api + '/food/vote', {
      method: 'PUT',
      body: data
    })
      .then((res) => res.json())
      .then((food: FoodType) => this.update(food))
      .catch((err) => environment.test && console.error(err));
	}

	render() {
		return (
			<div key={this.state.food.id} className="food">
				<img src={environment.backend.api + '/food/image?fileName=' + this.state.food.name} alt={'' + this.state.food.name} loading="lazy" />
				<IconButton aria-label="vote" onClick={() => this.vote()}>
					<span>{this.state.food.count}</span>
					<ThumbUpIcon fontSize="large" />
				</IconButton>
			</div>
		);
	}
};